import React from "react"
import { Helmet } from 'react-helmet'
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"

const ProjectTitle = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`

const ProjectPost = (props: any) => {
    const { title, meta_desc, html } = props.pageContext;
    return (
      <Layout largeWidthView={false}>
        <Helmet>
            <title>{title} | Harry Denley</title>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@sniko_" />
            <meta name="twitter:creator" content="@sniko_" />
            <meta name="twitter:title" content={`${title} - Harry Denley`} />
            {meta_desc && <meta name="twitter:description" content={`${meta_desc}`} />}
            {props.data?.file?.childImageSharp?.fixed && <meta name="twitter:image" content={`https://harrydenley.com/${props.data.file.childImageSharp.fixed.src}`} />}
        </Helmet>
        <ProjectTitle>
            <Link to="/">~/</Link>&nbsp;
            {title}
        </ProjectTitle>

        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    );
  };
  
  export default ProjectPost;


export const query = graphql`
  query ($MetaImgRegex: String) {
  file(absolutePath: {regex: $MetaImgRegex}) {
    childImageSharp {
      fixed(width: 800) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  }
`;